import mainConfig from 'mainConfig';

export const copyrightsWorldEndpoint = mainConfig['endpoints.copyrightsWorld'];
export const copyrightsBoundsEndpoint = mainConfig['endpoints.copyrightsBounds'];
export const copyrightsZoomEndpoint = mainConfig['endpoints.copyrightsZoom'];
export const captionEndpoint = mainConfig['endpoints.caption'];
export const copyrightsV2Endpoint = mainConfig['endpoints.copyrightsV2'];
export const captionV2Endpoint = mainConfig['endpoints.captionV2'];

//Search
export const batchSearchEndpoint = mainConfig['endpoints.batchSearch'];
export const batchSyncSearchEndpoint = mainConfig['endpoints.batchSyncSearch'];
export const batchSearchQueryEndpoint = mainConfig['endpoints.batchSearchQuery'];
export const geocodeEndpoint = mainConfig['endpoints.geocode'];
export const reverseGeocodeEndpoint = mainConfig['endpoints.reverseGeocode'];
export const batchReverseGeocodeQueryEndpoint = mainConfig['endpoints.batchReverseGeocodeQuery'];
export const structuredGeocodeEndpoint = mainConfig['endpoints.structuredGeocode'];
export const searchEndpoint = mainConfig['endpoints.search'];
export const batchStructuredGeocodeQueryEndpoint = mainConfig['endpoints.batchStructuredGeocodeQuery'];
export const adpEndpoint = mainConfig['endpoints.adp'];
export const batchAdpQueryEndpoint = mainConfig['endpoints.batchAdpQuery'];
export const nearbySearchEndpoint = mainConfig['endpoints.nearbySearch'];
export const batchNearbySearchQueryEndpoint = mainConfig['endpoints.batchNearbySearchQuery'];
export const autocompleteEndpoint = mainConfig['endpoints.autocomplete'];
export const poiCategoriesEndpoint = mainConfig['endpoints.poiCategories'];
export const chargingAvailabilityEndpoint = mainConfig['endpoints.chargingAvailability'];
export const batchChargingAvailabilityQueryEndpoint = mainConfig['endpoints.batchChargingAvailabilityQuery'];
export const poiDetailsEndpoint = mainConfig['endpoints.poiDetails'];
export const poiPhotosEndpoint = mainConfig['endpoints.poiPhotos'];
export const placeByIdEndpoint = mainConfig['endpoints.placeById'];

//Routing
export const routingEndpoint = mainConfig['endpoints.routing'];
export const calculateReachableRangeEndpoint = mainConfig['endpoints.calculateReachableRange'];
export const batchRoutingQueryEndpoint = mainConfig['endpoints.batchRoutingQuery'];
export const batchRoutingEndpoint = mainConfig['endpoints.batchRouting'];
export const batchSyncRoutingEndpoint = mainConfig['endpoints.batchSyncRouting'];
export const matrixRoutingEndpoint = mainConfig['endpoints.matrixRouting'];
export const matrixSyncRoutingEndpoint = mainConfig['endpoints.matrixSyncRouting'];
export const batchReachableRangeQueryEndpoint = mainConfig['endpoints.batchReachableRangeQuery'];
export const longDistanceEVRoutingEndpoint = mainConfig['endpoints.longDistanceEVRouting'];

//traffic
export const incidentDetailsEndpoint = mainConfig['endpoints.incidentDetails'];
export const incidentDetailsV5Endpoint = mainConfig['endpoints.incidentDetailsV5'];
export const incidentViewportEndpoint = mainConfig['endpoints.incidentViewport'];
export const flowSegmentDataEndpoint = mainConfig['endpoints.flowSegmentData'];
export const incidentRegionsEndpoint = mainConfig['endpoints.incidentRegions'];

//layers
export const trafficLayerEndpoint = mainConfig['endpoints.trafficLayer'];
export const rasterTrafficFlowTilesLayerEndpoint = mainConfig['endpoints.rasterTrafficFlowTilesLayer'];
export const vectorTrafficFlowTilesLayerEndpoint = mainConfig['endpoints.vectorTrafficFlowTilesLayer'];
export const tileLayerEndpoint = mainConfig['endpoints.tileLayer'];

export const staticMapImageEndpoint = mainConfig['endpoints.staticImage'];
export const origin = mainConfig.origin;
